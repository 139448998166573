@import "../base";

@include rein-menu;

#navigation {
	position: absolute;
	width: 100vw;
	top: 80px;
	left: 0;
	background: rgba(255,255,255,0.9);
	
	@include breakpoint(medium) {
		display: flex !important;
		position: initial;
		width: auto;
		background: initial;
	}
}

.menu {
	
	&--reinvent {
		flex-wrap: nowrap;
		flex-direction: column;
		justify-content: flex-end;
		width: calc(100% - 80px);
		background: #222222;
		text-align: center;
		
		@include breakpoint(medium) {
			padding: 0 150px 0 0;
			flex-wrap: wrap;
			flex-direction: row;
			width: initial;
			background: initial;
			text-align: initial;
		}
	}
	
	&--footer_bottom {
		a {
			padding: 27px 10px;
		}
	}
	
	&__link {
		
		color: $white;
		
		&:focus,
		&:hover,
		&:active {
			color: $white;
			text-decoration: underline;
		}
		
		@include breakpoint(medium) {
			color: $body-font-color;
			padding: 67px 15px !important;
			
			&:focus,
			&:hover,
			&:active {
				color: $body-font-color;
				text-decoration: underline;
				background: initial;
			}
		}
		
	}
}