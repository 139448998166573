// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group accordion
////

/// Default background color of an accordion group.
/// @type Color
$accordion-background: $white !default;

/// If `true`, adds plus and minus icons to the side of each accordion title.
/// @type Boolean
$accordion-plusminus: true !default;

/// Content for the plus icon when `$accordion-plusminus` is `true`
/// @type String
$accordion-plus-content: '\002B' !default;

/// Content for the minus icon when `$accordion-plusminus` is `true`
/// @type String
$accordion-minus-content: '\2013' !default;

/// Font size of accordion titles.
/// @type Number
$accordion-title-font-size: rem-calc(12) !default;

/// Default text color for items in a Menu.
/// @type Color
$accordion-item-color: $primary-color !default;

/// Default background color on hover for items in a Menu.
/// @type Color
$accordion-item-background-hover: $light-gray !default;

/// Default padding of an accordion item.
/// @type Number | List
$accordion-item-padding: 1.25rem 1rem !default;

/// Default background color of tab content.
/// @type Color
$accordion-content-background: $white !default;

/// Default border color of tab content.
/// @type Color
$accordion-content-border: 1px solid $light-gray !default;

/// Default text color of tab content.
/// @type Color
$accordion-content-color: $body-font-color !default;

/// Default padding for tab content.
/// @type Number | List
$accordion-content-padding: 1rem !default;

/// Adds styles for an accordion container. Apply this to the same element that gets `data-accordion`.
@mixin accordion-container (
    $background: $accordion-background
) {
    margin-#{$global-left}: 0;
    background: $background;
    list-style-type: none;
    
    &[disabled] {
        .accordion__title {
            cursor: not-allowed;
        }
    }
}

/// Adds styles for the accordion item. Apply this to the list item within an accordion ul.
@mixin pdp-accordion-item() {
    &:first-child > :first-child {
        border-radius: $global-radius $global-radius 0 0;
    }
    
    &:last-child > :last-child {
        border-radius: 0 0 $global-radius $global-radius;
    }
}

/// Adds styles for accordion content. Apply this to the content pane below an accordion item's title.
@mixin accordion-content (
    $padding: $accordion-content-padding,
    $border: $accordion-content-border,
    $background: $accordion-content-background,
    $color: $accordion-content-color
) {
    display: none;
    padding: $padding;
    width: 100%;
    border: $border;
    border-bottom: 0;
    background-color: $background;

    color: $color;

    :last-child > &:last-child {
        border-bottom: $border;
    }
}

@mixin pdp-accordion-content(
    $padding: $pdp-accordion-content-padding,
    $border: $pdp-accordion-content-border,
    $background: $pdp-accordion-content-background,
    $color: $pdp-accordion-content-color
) {
    display: none;
    padding: $padding;
    width: 100%;
    border-bottom: $border;
    background-color: $background;
    color: $color;
}

@mixin rein-accordion-title (
    $padding: $accordion-item-padding,
    $font-size: $accordion-title-font-size,
    $color: $accordion-item-color,
    $border: $accordion-content-border,
    $color-hover: $accordion-item-color-hover
) {
    position: relative;
    display: block;
    padding: $padding;
    
    border: $border;
    border-bottom: 0;
    
    font-size: $font-size;
    font-weight: bold;
    line-height: 1;
    color: $color;

    &:hover,
    &:focus,
    &:hover > &__icon,
    &:focus > &__icon {
        color: $color-hover;
    }
    
    @if $accordion-plusminus {
        &::before {
            position: absolute;
            top: 50%;
            #{$global-right}: 1rem;
            margin-top: -0.5rem;
            content: $accordion-plus-content;
        }
        
        .is_active > &::before {
            content: $accordion-minus-content;
        }
    }
}

@mixin pdp-accordion-title(
    $padding: $pdp-accordion-item-padding,
    $font-size: $pdp-accordion-title-font-size,
    $color: $pdp-accordion-item-color,
    $border: $pdp-accordion-content-border,
    $color-hover: $pdp-accordion-item-color-hover
) {
    position: relative;
    display: block;
    padding: $padding;
    border-bottom: $border;
    font-size: $font-size;
    font-weight: 900;
    line-height: 1;
    color: $color;

    &:hover,
    &:focus,
    &:hover > &__icon,
    &:focus > &__icon {
        color: $color-hover;
    }

    h2 {
        display: inline-block;
        margin: -4px 0;
    }
}

@mixin rein-accordion {
    .accordion {
        $parentItem: &;

        @include accordion-container;

        &__item {
    
            $accordion_item: &;
            
            #{$accordion_item}__title {
                border-bottom: 2px solid get-color(primary);
            }

            &.is_active {
    
                #{$accordion_item}__title {
                    
                    &__icon {
                        transform: rotate(180deg);
                    }
                }
            }
            
            &:not(:last-child).is_active {
                #{$accordion_item}__content {
                    border-bottom: 2px solid get-color(primary);
                }
            }

            &__title {
                @include rein-accordion-title;
                
                &__icon {
                    float: right;
                    transform: rotate(0deg);
                    transition: transform 0.25s ease-in-out;
                }
            }

            &__content {
                @include accordion-content;
            }
        }

        &--pdp {
            #{$parentItem}__item {
                
                @include pdp-accordion-item;
                
                &--active {
                    
                    .accordion__item__title {
    
                        &:hover,
                        &:focus {
                            cursor: default;
                            color: initial;
                        }
                        
                        &__text {
                            color: initial !important;
                        }
                        
                    }
                    
                    .accordion__item__content {
                        display: block;
                        border-bottom: none;
                        
                        &__advise_img {
                            display: block;
                            margin: 0.5rem 0;
                            max-width: 150px;
                        }
                    }
                }

                &__title {
                    @include pdp-accordion-title;
                }

                &__content {
                    @include pdp-accordion-content;
                }
            }
        }

        &--footer{
            #{$parentItem}__item {
                &__title {
                    @include pdp-accordion-title;
                    border-bottom: 2px solid get-color(primary);
                }

                &__content {
                    @include pdp-accordion-content;
                    background-color: unset;
                }
            }
        }
    }
}