// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group Flexbox Utilities
////
//
/// Default value for the count of source ordering`
/// @type Number
$flex-source-ordering-count: 6 !default;

/// Quickly disable/enable Responsive breakpoints for Vanilla Flex Helpers.
/// @type Boolean
$flexbox-responsive-breakpoints: true !default;

@mixin flex-helpers {
    .flex_container {
        @include flex;
    }

    .flex_child_auto {
        flex: 1 1 auto;
    }

    .flex_child_grow {
        flex: 1 0 auto;
    }

    .flex_child_shrink {
        flex: 0 1 auto;
    }
    
    @each $dir, $prop in $-zf-flex-direction {
        .flex_dir_#{$dir} {
            @include flex-direction($prop);
        }
    }
    
    @if ($flexbox-responsive-breakpoints) {
        // Loop through Responsive Breakpoints
        @each $size in $breakpoint-classes {
            @include breakpoint($size) {
                @if $size != $-zf-zero-breakpoint {
                    .#{$size}_flex_container {
                        @include flex;
                    }

                    .#{$size}_flex_child_auto {
                        flex: 1 1 auto;
                    }

                    .#{$size}_flex_child_grow {
                        flex: 1 0 auto;
                    }

                    .#{$size}_flex_child_shrink {
                        flex: 0 1 auto;
                    }
                    
                    @each $dir, $prop in $-zf-flex-direction {
                        .#{$size}_flex_dir_#{$dir} {
                            @include flex-direction($prop);
                        }
                    }
                }
            }
        }
    }
}

@mixin rein-flex-classes {
    // Horizontal alignment using justify-content
    @each $hdir, $prop in $-zf-flex-justify {
        .align_#{$hdir} {
            @include flex-align($x: $hdir);
        }
    }
    
    // Horizontal alignment Specifically for Vertical Menu
    @each $hdir, $prop in map-remove($-zf-flex-justify, 'justify', 'spaced') {
        .align_#{$hdir} {
            &--vertical {
                &.menu > li > a {
                    @include flex-align($x: $hdir);
                }
            }
        }
    }
    
    // Vertical alignment using align-items and align-self
    @each $vdir, $prop in $-zf-flex-align {
        .align_#{$vdir} {
            @include flex-align($y: $vdir);
        }

        .align_self_#{$vdir} {
            @include flex-align-self($y: $vdir);
        }
    }
    
    // Central alignment of content
    .align_center_middle {
        @include flex-align($x: center, $y: middle);
        align-content: center;
    }
    
    // Source ordering
    @include -zf-each-breakpoint {
        @for $i from 1 through $flex-source-ordering-count {
            .#{$-zf-size}_order_#{$i} {
                @include flex-order($i);
            }
        }
    }
    
    // Vanilla Flexbox Helpers
    @include flex-helpers;
}