@import "../base";

@include rein-button;

.button {
	
	&:focus,
	&:hover,
	&:active {
		color: get-color(secondary);
	}
	
	&--cta {
		background: linear-gradient(90deg, rgba(72,193,233,1) 50%, rgba(134,188,36,1) 100%);
		background-size: 200% 100%;
		background-position: right bottom;
		color: $white;
		transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
		
		&:focus,
		&:hover,
		&:active {
			background-position: left bottom;
			color: $white;
		}
	}
}