@import "../base";

footer {
	font-size: rem-calc(14);
	color: $white;
	
	a {
		color: $white;
		
		&:focus,
		&:hover,
		&:active {
			color: $white;
			text-decoration: underline;
		}
		
	}
	
	.footer {
		
		&__top {
			background: #222222;
		}
		
		&__bottom {
			background: #000000;
			padding: 27px 0;
			
			&__link {
				display: block;
				color: $white;
				margin-left: 27px;
				padding-bottom: 10px;

				@include breakpoint(small) {
					padding-bottom: 0;
				}

				
				&:focus,
				&:hover,
				&:active {
					text-decoration: underline;
				}
			}

			&__social_link{
				margin-right: 15px;
				transition: 0.25s ease-in-out;

				&:last-child{
					margin-right: 0;
				}

				&:focus,
				&:hover,
				&:active {
					opacity: 0.8;
					transition: 0.25s ease-in-out;
				}

				a{
					padding: unset;
				}
			}
		}
		
		.social_logo {
			width: 20px;
			height: 20px;
		}
	}
	
	h3 {
		margin: 0;
	}
}