@import "../base";

header {
    
    position: relative;
    z-index: 2;
    
    .head {
        &__wrapper {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 100%;
            max-width: $global-width;
        }
    }
    
    .head {
        display: flex;
        
        &.is_stuck {
            background: rgba(255,255,255,0.9);
            
            .head__tile--logo {
                @include breakpoint(medium) {
                    padding: 20px 20px 20px 0;
                }
            }
            
            .menu__link {
                @include breakpoint(medium) {
                    padding: 32px 15px !important;
                }
            }
        }
        
        &__wrapper {
            
            @include breakpoint(large) {
                flex-wrap: nowrap;
            }
            
            &__group {
                display: flex;
                flex: 1 1 auto;
                
                &--end {
                    justify-content: flex-end;
                }
            }
        }
        
        &__tile {
            display: flex;
            align-items: center;
            height: $head-height-mobile;
            
            &:focus,
            &:hover {
                cursor: pointer;
            }
            
            &--logo {
                padding: 20px;
                
                @include breakpoint(medium) {
                    padding: 75px 20px 75px 20px;
                }
    
                @include breakpoint(large) {
                    padding: 75px 20px 75px 0;
                }
            }
            
            &__button {
                
                position: relative;
                padding: 27px 24px;
                color: $white;
                background: linear-gradient(45deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 50%);
                background-size: 200% 100%;
                background-position: right top;
                transition: all 0.3s cubic-bezier(0.4, 0, 1, 1);
    
                &--active,
                &:focus,
                &:hover {
                    background-position: left bottom;
                }
                
                &--active {
                    .head__tile__button {
                        &__inner {
                            transform: rotate(45deg);
                            transition-delay: 0.12s;
                            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    
                            &::before {
                                top: 0;
                                opacity: 0;
                                transition: top 0.075s ease,
                                opacity 0.075s 0.12s ease;
                            }
    
                            &::after {
                                bottom: 0;
                                transform: rotate(-90deg);
                                transition: bottom 0.075s ease,
                                transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
                            }
                        }
                    }
                }
                
                &__outer {
                    width: 32px;
                    height: 4px * 3 + 6px * 2;
                    display: inline-block;
                    position: relative;
                }
                
                &__inner {
                    display: block;
                    top: 50%;
                    margin-top: -2px;
                    transition-duration: 0.075s;
                    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    
                    &,
                    &::before,
                    &::after {
                        width: 100%;
                        height: 4px;
                        background-color: $white;
                        position: absolute;
                        transition-property: transform;
                        transition-duration: 0.15s;
                        transition-timing-function: ease;
                    }
    
                    &::before,
                    &::after {
                        content: "";
                        display: block;
                    }
    
                    &::before {
                        top: (6px + 4px) * -1;
                        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
                    }
    
                    &::after {
                        bottom: (6px + 4px) * -1;
                        transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
                    }
                }
            }
        }
    }
}