// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group float
////

@mixin rein-float-classes {
    .float_left {
        float: left !important;
    }

    .float_right {
        float: right !important;
    }

    .float_center {
        display: block;
        margin-right: auto;
        margin-left: auto;
    }
    
    .clearfix {
        @include clearfix;
    }
}