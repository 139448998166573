// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

@mixin rein-sticky {
    .sticky__container {
        position: relative;
    }
    
    .sticky {
        position: relative;
        z-index: 0;
        transform: translate3d(0, 0, 0);
    }

    .sticky.is_stuck {
        position: fixed;
        z-index: 5;
        width: 100%;

        &.is_at_top {
            top: 0;
        }

        &.is_at_bottom {
            bottom: 0;
        }
    }

    .sticky.is_anchored {
        position: relative;
        right: auto;
        left: auto;

        &.is_at_bottom {
            bottom: 0;
        }
    }
}