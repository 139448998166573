/**
* Foundation for Sites
* Version 6.6.3
* https://get.foundation
* Licensed under MIT Open Source
*/

// --- Dependencies ---
@import
    "global/vendor/normalize",
    "global/vendor/sassy-lists/stylesheets/helpers/missing-dependencies",
    "global/vendor/sassy-lists/stylesheets/helpers/true",
    "global/vendor/sassy-lists/stylesheets/functions/contain",
    "global/vendor/sassy-lists/stylesheets/functions/purge",
    "global/vendor/sassy-lists/stylesheets/functions/remove",
    "global/vendor/sassy-lists/stylesheets/functions/replace",
    "global/vendor/sassy-lists/stylesheets/functions/to-list";

// --- Utilities ---
@import "global/util/util";

// Import of shop settings
@import "settings";

// --- Global variables ---
@import
    "global/variables/global";

// --- Mixins ---
@import
    "global/mixins/grid/grid",
    "global/mixins/xy-grid/xy-grid",
    "global/reinvent_global",
    "components/reinvent_mixins";

// --- Motion UI ---
@import 'global/motion_ui/util/animation';
@import 'global/motion_ui/util/args';
@import 'global/motion_ui/util/function';
@import 'global/motion_ui/util/keyframe';
@import 'global/motion_ui/util/selector';
@import 'global/motion_ui/util/series';
@import 'global/motion_ui/util/transition';
@import 'global/motion_ui/util/unit';
@import 'global/motion_ui/util/string';

@import 'global/motion_ui/effects/fade';
@import 'global/motion_ui/effects/hinge';
@import 'global/motion_ui/effects/spin';
@import 'global/motion_ui/effects/zoom';
@import 'global/motion_ui/effects/shake';
@import 'global/motion_ui/effects/slide';
@import 'global/motion_ui/effects/wiggle';

@import 'global/motion_ui/transitions/fade';
@import 'global/motion_ui/transitions/hinge';
@import 'global/motion_ui/transitions/zoom';
@import 'global/motion_ui/transitions/slide';
@import 'global/motion_ui/transitions/spin';

@import 'global/motion_ui/classes';


@mixin rein-base(
    $flex: true,
    $xy-grid: $xy-grid
) {
    @if $flex {
        $global-flexbox: true !global;
    }

    @if $xy-grid {
        $xy-grid: true !global;
    }

    // Global styles
    @include foundation-global-styles;
    @include rein-typography;

    // Grids
    @if not $flex {
        @include foundation-grid;
    }
    @else {
        @if $xy-grid {
            @include foundation-xy-grid-classes;
        }
        @else {
            @include foundation-flex-grid;
        }
    }

    // Helpers
    @include rein-float-classes;
    @if $flex { @include rein-flex-classes; }
    @include rein-visibility-classes;
    @include rein-sticky;
    @include motion-ui-transitions;
    //@include motion-ui-animations;
}