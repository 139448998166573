// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group menu
////

/// Margin of a menu.
/// @type Number
$menu-margin: 0 !default;

/// Left-hand margin of a nested menu.
/// @type Number
$menu-nested-margin: $global-menu-nested-margin !default;

/// Padding for items in a pill menu.
/// @type Number
$menu-items-padding: $global-menu-padding !default;

/// margin for items in a simple menu.
/// @type Number
$menu-simple-margin: 1rem !default;

/// Text color of an active menu item.
/// @type Color
$menu-item-color-active: $white !default;

/// Alternative text color of an active menu item..
/// @type Color
$menu-item-color-alt-active: $black !default;

/// Background color of an active menu item.
/// @type Color
$menu-item-background-active: get-color(primary) !default;

/// Spacing between an icon and text in a menu item.
/// @type Number
$menu-icon-spacing: 0.25rem !default;

/// Backward compatibility for menu state. If true, this duplicate `active` with `is-active`.
/// But please note that `active` will be removed in upcoming versions.
/// @type Boolean
$menu-state-back-compat: true !default;

/// Backward compatibility for menu centered. If true, this duplicate `.menu-centered > .menu` with `.menu.align-center`.
/// But please note that `menu-centered` will be removed in upcoming versions.
/// @type Boolean
$menu-centered-back-compat: true !default;

/// Backward compatibility for using `icon-*` classes without `.icons` classes
/// But please note that this backward compatibility will be removed in upcoming versions.
/// @type Boolean
$menu-icons-back-compat: true !default;

/// Creates the base styles for a Menu.
@mixin menu-base {
    padding: 0;
    margin: 0;
    list-style: none;
    position: relative;
    
    @if $global-flexbox {
        display: flex;
        flex-wrap: wrap;
    }
    
    li {
        @include disable-mouse-outline;
    }
    
    a,
    .button {
        line-height: 1;
        padding: $menu-items-padding;
    }
    
    a {
        display: inline-block;
    }
    
    .button {
        display: block;
    }
    
    .button--clear {
        padding: 0;
    }

    // Reset styles of inner elements
    input,
    select,
    a,
    button {
        margin-bottom: 0;
    }
    
    input {
        display: inline-block;
    }
}

/// Expands the items of a Menu, so each item is the same width.
@mixin menu-expand {
    @if $global-flexbox {
        li {
            flex: 1 1 0px; // sass-lint:disable-line zero-unit
        }
    }
    @else {
        display: table;
        width: 100%;
        
        > li {
            display: table-cell;
            vertical-align: middle;
        }
    }
}

/// Align menu items.
@mixin menu-align($alignment) {
    @if $alignment == left {
        @if $global-flexbox {
            justify-content: flex-start;
        }
        @else {
            text-align: $global-left;
        }
    }
    @else if $alignment == right {
        @if $global-flexbox {
           & {
                display: flex;
                justify-content: flex-end;

                .menu__submenu li {
                    justify-content: flex-start;
                }
            }

            &--vertical li {
                display: block;
                text-align: $global-right;

                .menu__submenu li {
                    text-align: $global-right;
                }
            }
        }
        @else {
            text-align: $global-right;

            .menu__submenu li {
                text-align: $global-left;
            }

            &--vertical {
                .menu__submenu li {
                    text-align: $global-right;
                }
            }
        }
    }
    @else if $alignment == center {
        @if $global-flexbox {
            li {
                display: flex;
                justify-content: center;

                .menu__submenu li {
                    justify-content: flex-start;
                }
            }
        }
        @else {
            text-align: center;

            &__submenu li {
                text-align: $global-left;
            }
        }
    }
}

/// Sets the direction of a Menu.
/// @param {Keyword} $dir [horizontal] - Direction of the Menu. Can be `horizontal` or `vertical`.
@mixin menu-direction($dir: horizontal) {
    @if $dir == horizontal {
        @if $global-flexbox {
            flex-wrap: wrap;
            flex-direction: row;
        }
        @else {
            li {
                display: inline-block;
            }
        }
    }
    @else if $dir == vertical {
        @if $global-flexbox {
            flex-wrap: nowrap;
            flex-direction: column;
        }
        @else {
            li {
                display: block;
            }
        }
    }
    @else {
        @warn 'The direction used for menu-direction() must be horizontal or vertical.';
    }
}

/// Creates a simple Menu, which has no padding or hover state.
/// @param {Keyword} $dir [$global-left] - Direction of the menu. This effects the side of the `<li>` that receives the margin.
/// @param {Number} $margin [$menu-simple-margin] - The margin to apply to each `<li>`.
@mixin menu-simple($dir: $global-left, $margin: $menu-simple-margin) {
    @if $global-flexbox {
        align-items: center;
    }
    
    li + li {
        margin-#{$dir}: $margin;
    }
    
    a {
        padding: 0;
    }
}

/// Adds styles for a nested Menu, by adding `margin-left` to the menu.
/// @param {Keyword|Number} $margin [$menu-nested-margin] - Length of the margin.
/// @param {Keyword} $nested-alignment [left] - Alignment of the nested class
@mixin menu-nested(
    $margin: $menu-nested-margin,
	$nested-alignment: left
) {
    @if $nested-alignment == right {
        margin-#{$global-right}: $margin;
        margin-#{$global-left}: 0;
    }
    @else {
        margin-#{$global-right}: 0;
        margin-#{$global-left}: $margin;
    }
    
}

/// Adds basic styles for icons in menus.
@mixin menu-icons() {
    @if $global-flexbox {
        a {
            display: flex;
        }
    }
    @else {
        img,
        i,
        svg {
            vertical-align: middle;
            
            + span {
                vertical-align: middle;
            }
        }
    }
}

/// Align top/bottom icons within a menu.
/// @param {Keyword} $alignment [center] - Alignment of the icon
@mixin menu-icon-alignment($alignment: center) {
    @if $global-flexbox {
        &.icon-top,
        &.icon-bottom {
            li a {
                img,
                i,
                svg {
                    @if $alignment == left {
                        text-align: left;
                    }
                    
                    @else if $alignment == right {
                        text-align: right;
                    }
                    
                    @else {
                        text-align: center;
                    }
                }
            }
        }
    }
}

/// Adds position classes for icons within a menu.
@mixin menu-icon-position($position: left, $spacing: $menu-icon-spacing) {
    @if $position == left {
        li a {
            @if $global-flexbox {
                flex-flow: row nowrap;
            }
            
            img,
            i,
            svg {
                margin-#{$global-right}: $spacing;
                
                @if not $global-flexbox {
                    display: inline-block;
                }
            }
        }
    }
    @else if $position == right {
        li a {
            @if $global-flexbox {
                flex-flow: row nowrap;
            }
            
            img,
            i,
            svg {
                margin-#{$global-left}: $spacing;
                
                @if not $global-flexbox {
                    display: inline-block;
                }
            }
        }
    }
    @else if $position == top {
        li a {
            @if $global-flexbox {
                flex-flow: column nowrap;
            }
            @else {
                text-align: center;
            }
            
            img,
            i,
            svg {
                @if not $global-flexbox {
                    display: block;
                    margin: 0 auto $spacing;
                }
                @else {
                    align-self: stretch;
                    margin-bottom: $spacing;
                    text-align: center;
                }
            }
        }
    }
    @else if $position == bottom {
        li a {
            @if $global-flexbox {
                flex-flow: column nowrap;
            }
            @else {
                text-align: center;
            }
            
            img,
            i,
            svg {
                @if not $global-flexbox {
                    display: block;
                    margin: $spacing auto 0;
                }
                @else {
                    align-self: stretch;
                    margin-bottom: $spacing;
                    text-align: center;
                }
            }
        }
    }
}

@mixin menu-text {
    padding: $global-menu-padding;
    
    font-weight: bold;
    line-height: 1;
    color: inherit;
}

@mixin menu-state-active {
    background: $menu-item-background-active;
    color: color-pick-contrast($menu-item-background-active, ($menu-item-color-active, $menu-item-color-alt-active));
}

@mixin rein-menu {
    .menu {
        @include menu-base;
        
        // Default orientation: horizontal
        &, &--horizontal {
            @extend .menu;
            @include menu-direction(horizontal);
        }
        
        // Vertical orientation modifier
        &--vertical {
            @extend .menu;
            @include menu-direction(vertical);
            @include menu-icon-alignment(left);
        }
        
        // Even-width modifier for horizontal orientation
        &--expanded {
            @extend .menu;
            @include menu-expand;
            @include menu-icon-alignment(left);
        }
        
        // Simple
        &--simple {
            @extend .menu;
            @include menu-simple;
        }
        
        // Breakpoint specific versions
        @include -zf-each-breakpoint($small: false) {
            &--#{$-zf-size}_horizontal {
                @include menu-base;
                @include menu-direction(horizontal);
            }

            &--#{$-zf-size}_vertical {
                @include menu-base;
                @include menu-direction(vertical);
            }

            &--#{$-zf-size}_expanded {
                @include menu-base;
                @include menu-expand;
            }

            &--#{$-zf-size}_simple {
                @include menu-base;
                @include menu-simple;
            }
        }
        
        // Nesting
        &.nested {
            @include menu-nested;
        }
        
        // Icon Base Styles
        &.icons {
            @include menu-icons;
        }
        
        // Backward Compatibility for active state
        @if $menu-icons-back-compat {
            &--icon_top,
            &--icon_right,
            &--icon_bottom,
            &--icon_left {
                @extend .menu;
                @include menu-icons;
            }
        }

        // Icon Left
        &--icon_left {
            @extend .menu;
            @include menu-icon-position(left);
        }

        // Icon Right
        &--icon_right {
            @extend .menu;
            @include menu-icon-position(right);
        }

        // Icon Top
        &--icon_top {
            @extend .menu;
            @include menu-icon-position(top);
        }

        // Icon Bottom
        &--icon_bottom {
            @extend .menu;
            @include menu-icon-position(bottom);
        }
        
        // Active state
        .is_active > a {
            @include menu-state-active;
        }
        
        // Backward Compatibility for active state
        @if $menu-state-back-compat {
            .active > a {
                @include menu-state-active;
            }
        }
        
        // Align left
        &--align_#{$global-left} {
            @extend .menu;
            @include menu-align(left);
        }
        
        // Align right
        &--align_#{$global-right} {
            @extend .menu;
            @include menu-align(right);
            @include menu-icon-alignment(right);
            
            .nested {
                @include menu-nested($nested-alignment: right);
            }
        }
        
        // Align center
        &--align_center {
            @extend .menu;
            @include menu-align(center);
        }

        &--text {
            @extend .menu;
            @include menu-text;
        }
    }
    
    @if $menu-centered-back-compat {
        .menu--centered {
            @extend .menu;
            
            > .menu {
                @if $global-flexbox {
                    justify-content: center;
                }
                
                @include menu-align(center);
            }
        }
    }
    
    // Prevent FOUC when using the Responsive Menu plugin
    .no_js [data-responsive-menu] ul {
        display: none;
    }
}