@import "../base";

@include rein-card;

.card {
	
	&--fixed_width {
		margin: 0 auto;
		
		.card__section {
			
			padding: 80px 20px;
			
			@include breakpoint(medium) {
				padding: 80px;
			}
		}
		
		@include breakpoint(medium) {
			max-width: $global-width;
		}
		
		.button {
			margin: 20px 0;
			
			@include breakpoint(medium) {
				margin: 0;
			}
		}
	}
	
	&--pixelheads {
		position: relative;
		width: calc(100% - 70px);
		background: linear-gradient(45deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);
		color: $white;
		overflow: initial;
		
		.card__section {
			border: none;
			
			@include breakpoint(medium) {
				padding: 40px;
			}
		}
	}
	
	&--pixel_left {
		
		float: right;
		margin: 0 0 150px 0;
		
		@include breakpoint(medium) {
			margin: 0;
		}
		
		&:before {
			position: absolute;
			display: block;
			content:'';
			right: 100%;
			top: 100%;
			width: 70px;
			height: 70px;
			background: get-color(primary);
		}
		
		&--end {
			@extend .card--pixel_left;
			margin: 0;
		}
	}
	
	&--pixel_right {
		
		float: left;
		margin: 0 0 70px 0;
		
		@include breakpoint(medium) {
			margin: 0;
		}
		
		&:after {
			position: absolute;
			display: block;
			content:'';
			left: 100%;
			bottom: 100%;
			width: 70px;
			height: 70px;
			background: get-color(secondary);
		}
		
		&--end {
			@extend .card--pixel_right;
			margin: 0;
		}
	}

	&--benefit{
		min-height: 150px;
		height: 100%;
		background: transparent;
		-webkit-box-shadow: 0px 3px 19px 1px $light-gray;
		box-shadow: 0px 3px 19px 1px $light-gray;
	}
}