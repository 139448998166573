// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group card
////

/// Default background color.
/// @type Color
$card-background: $white !default;

/// Default font color for cards.
/// @type Color
$card-font-color: $body-font-color !default;

/// Default background.
/// @type Color
$card-divider-background: $light-gray !default;

/// Default border style.
/// @type List
$card-border: 1px solid $light-gray !default;

/// Default card shadow.
/// @type List
$card-shadow: none !default;

/// Default border radius.
/// @type List
$card-border-radius: $global-radius !default;

/// Default padding.
/// @type Number
$card-padding: $global-padding !default;

/// Default bottom margin.
/// @type number
$card-margin-bottom: $global-margin !default;

/// Adds styles for a card container.
/// @param {Color} $background - Background color of the card.
/// @param {Color} $color - font color of the card.
/// @param {Number} $margin - Bottom margin of the card.
/// @param {List} $border - Border around the card.
/// @param {List} $radius - border radius of the card.
/// @param {List} $shadow - box shadow of the card.
@mixin card-container(
    $background: $card-background,
    $color: $card-font-color,
    $margin: $card-margin-bottom,
	$radius: $card-border-radius,
	$shadow: $card-shadow
) {
    @if $global-flexbox {
        display: flex;
        flex-direction: column;
        flex-grow: 1;
    }
    
    position: relative;
    margin-bottom: $margin;
    border-radius: $radius;
    background: $background;
    box-shadow: $shadow;
    color: $color;
    
    & > :last-child {
        margin-bottom: 0;
    }
}

/// Adds styles for a card divider.
@mixin card-divider(
    $background: $card-divider-background,
) {
    @if $global-flexbox {
        display: flex;
        flex: 0 1 auto;
    }
    
    background: $background;
    
    & > :last-child {
        margin-bottom: 0;
    }
}

/// Adds styles for a card section.
@mixin card-section(
    $padding: $card-padding,
    $border: $card-border,   // Border only for section; not the whole Element -- streiber
    $radius: $card-border-radius
) {
    @if $global-flexbox {
        flex: 1 0 auto;
    }

    padding: $padding * 0.5;
    border: $border;
    border-radius: $radius;

    & > :last-child:not(h1),
    & > :last-child:not(h2),
    & > :last-child:not(h3),
    & > :last-child:not(h4),
    & > :last-child:not(h5),
    & > :last-child:not(h6),
    & > :last-child:not(.h1),
    & > :last-child:not(.h2),
    & > :last-child:not(.h3),
    & > :last-child:not(.h4),
    & > :last-child:not(.h5),
    & > :last-child:not(.h6) {
        margin-bottom: 0;
    }
    
    @include breakpoint(medium) {
        padding: $padding;
    }
}

@mixin rein-card {
    .card {
        $card: &;
        @include card-container;
        
        &--promote {
    
            @extend .card;
            
            #{$card}__section {
                background: get-color(secondary);
            }
        }
        
        &--interaction {
            @extend .card;
    
            #{$card}__section {
                &:focus,
                &:hover {
                    border-color: get-color(primary);
                    cursor: pointer;
                }
            }
        }

        &__divider {
            @include card-divider;
        }

        &__section {
            @include card-section;
        }

        // For IE 11 - Flexbug
        // https://github.com/philipwalton/flexbugs/issues/75
        &__image {
            min-height: 1px;
        }
    }
}