// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group typography-helpers
////

/// Default font size for lead paragraphs.
/// @type Number
$lead-font-size: $global-font-size * 1.25 !default;

/// Default line height for lead paragraphs.
/// @type String
$lead-lineheight: 1.6 !default;

/// Default line height for subheaders.
/// @type Number
$subheader-lineheight: 1.4 !default;

/// Default font color for subheaders.
/// @type Color
$subheader-color: $dark-gray !default;

/// Default font weight for subheaders.
/// @type String
$subheader-font-weight: $global-weight-normal !default;

/// Default top margin for subheaders.
/// @type Number
$subheader-margin-top: 0.2rem !default;

/// Default bottom margin for subheaders.
/// @type Number
$subheader-margin-bottom: 0.5rem !default;

/// Default font size for statistic numbers.
/// @type Number
$stat-font-size: 2.5rem !default;

/// Text color for `.cite-block` component.
/// @type Color
$cite-color: $dark-gray !default;

/// Font size for `.cite-block` component.
/// @type Number
$cite-font-size: rem-calc(13) !default;

/// Pseudo content for `.cite-block` component.
/// @type String
$cite-pseudo-content: '\2014 \0020' !default;

/// Text color of `.code-inline` and `.code-block` components.
/// @type Color
$code-color: $black !default;

/// Font family of `.code-inline` and `.code-block` components.
/// @type String | List
$code-font-family: $font-family-monospace !default;

/// Font weight of text in `.code-inline` and `.code-block` components.
/// @type String
$code-font-weight: $global-weight-normal !default;

/// Background color of `.code-inline` and `.code-block` components.
/// @type Color
$code-background: $light-gray !default;

/// Border around `.code-inline` and `.code-block` components.
/// @type List
$code-border: 1px solid $medium-gray !default;

/// Padding around text of the `.code-inline` component.
/// @type Number | List
$code-padding: rem-calc(2 5 1) !default;

/// Padding around text of the `.code-block` component.
/// @type Number | List
$code-block-padding: 1rem !default;

/// Margin under the `.code-block` component.
/// @type Number
$code-block-margin-bottom: 1.5rem !default;

@mixin cite-block {
    display: block;
    color: $cite-color;
    font-size: $cite-font-size;
    
    &:before {
        content: $cite-pseudo-content;
    }
}

/// Add basic styles for a code helper.
/// See `code-inline` and `code-block` mixins.
@mixin code-style {
    border: $code-border;
    background-color: $code-background;
    
    font-family: $code-font-family;
    font-weight: $code-font-weight;
    color: $code-color;
}

/// Make code helper from the `code-style` mixin inline.
/// Used to generate `.code-inline`
@mixin code-inline {
    display: inline;
    max-width: 100%;
    word-wrap: break-word;
    
    padding: $code-padding;
}

/// Make code helper from the `code-style` mixin a block.
/// Used to generate `.code-block`
@mixin code-block {
    display: block;
    overflow: auto;
    white-space: pre;
    
    padding: $code-block-padding;
    margin-bottom: $code-block-margin-bottom;
}

@mixin rein-typography-helpers {
    // Use to create a subheading under a main header
    // Make sure you pair the two elements in a <header> element, like this:
    // <header>
    //   <h1>Heading</h1>
    //   <h2>Subheading</h2>
    // </header>
    .subheader {
        margin-top: $subheader-margin-top;
        margin-bottom: $subheader-margin-bottom;
        
        font-weight: $subheader-font-weight;
        line-height: $subheader-lineheight;
        color: $subheader-color;
    }
    
    // Use to style an introductory lead, deck, blurb, etc.
    .lead {
        font-size: $lead-font-size;
        line-height: $lead-lineheight;
    }
    
    // Use to style a large number to display a statistic
    .stat {
        font-size: $stat-font-size;
        line-height: 1;
        
        p + & {
            margin-top: -1rem;
        }
    }
    
    ul, ol {
        // Use to remove numbers from ordered list & bullets from unordered list
        &.no-bullet {
            margin-#{$global-left}: 0;
            list-style: none;
        }
    }
    
    %cite_block {
        @include cite-block;
    }
    
    .cite_block {
        @extend %cite_block;
    }
    
    %code_inline {
        @include code-style;
        @include code-inline;
    }
    
    .code_inline {
        @extend %code_inline;
    }
    
    .code_block {
        @include code-style;
        @include code-block;
    }
}