@import "../base";

.rein_logo {
	width: 90px;
	height: 28px;
	
	&--footer {
		
		margin: 70px 0 55px 0;
		
		@include breakpoint(medium) {
			margin: 90px 0 70px 0;
		}
	}
}

.claim {
	
	@include breakpoint(large) {
		max-width: $global-width;
		margin: 0 auto;
	}
	
	&__container {
		position: relative;
		width: 100%;
		padding: 0;
		
		@include breakpoint(medium) {
			width: calc(100% - 150px);
			padding: 120px 85px;
			background: linear-gradient(45deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);
			
			&:after {
				position: absolute;
				display: block;
				content: "";
				left: 100%;
				bottom: 100%;
				width: 150px;
				height: 150px;
				background: get-color(secondary);
			}
		}
	}
	
	
	&__title {
		color: $white;
		width: calc(100% - 80px);
		padding: 80px 20px 40px 20px;
		background: linear-gradient(45deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);
		
		&--regular {
			font-weight: normal;
		}
		
		@include breakpoint(small) {
			padding: 80px 180px 40px 20px;
		}
		
		@include breakpoint(medium) {
			font-size: rem-calc(56px);
			line-height: 67px;
			width: auto;
			padding: initial;
			margin: 0 0 30px 0;
			background: transparent;
			max-width: 70%;
		}
	}
	
	&__info {
		width: calc(100% - 80px);
		padding: 0 16px;
		
		@include breakpoint(medium) {
			width: auto;
			padding: initial;
			color: $white;
			max-width: 70%;
		}
		
		&__text {
			display: flex;
			font-size: rem-calc(18);
			margin: 0 0 20px 0;
			
			@include breakpoint(medium) {
				font-size: rem-calc(20px);
				line-height: 140%;
				margin: 0 0 30px 0;
			}
		}
		
		&__cta {
			@extend .button--cta;
			
			@include breakpoint(medium) {
				@include button;
				background: initial;
				
				&:focus,
				&:hover,
				&:active {
					color: get-color(secondary);
				}
			}
		}
	}
}

.section {
	
	&__content {
		
		&--rein {
			
			padding: 60px 0;
			
			@include breakpoint(medium) {
				padding: 120px 0;
				max-width: 80%;
			}
		}
		
		&--rein_shops {
			margin: 0 0 60px 0;
		}
	}
}


#rein_shops {
	
	padding: 60px 0 0 0;
	
	@include breakpoint(medium) {
		padding: 150px 0 0 0;
	}
	
	.shop {
		
		&__logo {
			display: flex;
			width: 111px;
			height: 111px;
			background: $white;
			
			#logo_voelkner,
			#logo_digitalo,
			#logo_smdv {
				width: 80px;
				margin: 0 auto;
			}
		}
		
		&__data {
			font-size: rem-calc(23);
			font-weight: bold;
			line-height: 134%;
			background: linear-gradient(90deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 100% 100%;
			background-position: 100%;
		}
		
		&__info {
			margin-top: 20px;
			margin-bottom: 80px;
			
			@include breakpoint(medium) {
				margin-top: 40px;
				margin-bottom: 100px;
			}
			
			&--end {
				margin-bottom: 0;
			}
		}
	}
}

#be_proud{

	padding: 80px 0 0 0;

	@include breakpoint(medium) {
		padding: 150px 0 0 0;
	}

	.pixelworld{

		&__collage{
			margin: 0 0 40px 0;
			padding: 80px 40px;
			overflow: hidden;

			background: linear-gradient(45deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);

			@include breakpoint(small) {
				padding: 80px;
			}

			@include breakpoint(medium) {
				padding: 125px;
			}

			&__polaroid{

				&--rotate_none{
					border: 2px solid $light-gray;

					@include breakpoint(small) {
						margin: 0;
					}
				}

				&--rotate_left{
					transform: translateX(50%) rotate(0deg);
					border: 2px solid $light-gray;
					margin-top: 0;

					@include breakpoint(small) {
						transform: translateX(50%) rotate(-2deg);
						margin-top: -60px;
					}
				}

				&--rotate_right{
					transform: translateX(-50%) rotate(0deg);
					border: 2px solid $light-gray;
					margin-top: 0px;

					@include breakpoint(small) {
						transform: translateX(-50%) rotate(2deg);
						margin-top: -60px;
					}
				}
			}
		}
	}



}

#recruiting {
	
	padding: 80px 0 0 0;
	
	@include breakpoint(medium) {
		padding: 150px 0 0 0;
	}
	
	.benefit {
		
		//First box with headline
		&__heading_box {
			padding: $global-padding;
			background: linear-gradient(45deg, rgba(72, 193, 233, 1) 0%, rgba(134, 188, 36, 1) 100%);
			border: none;
			height: 100%;
			min-height: 180px;
			display: flex;
			justify-content: center;
			align-items: center;
			
			&__headline {
				font-size: rem-calc(24);
				font-weight: bold;
				color: $white;
			}
		}
		
		&__icon {
			width: 32px;
			height: 32px;
			margin: 0 0 $global-margin 0;
		}
		
		&__headline {
			background: linear-gradient(90deg, #48c1e9 0%, #86bc24 100%);
			background-clip: text;
			-webkit-background-clip: text;
			-webkit-text-fill-color: transparent;
			background-size: 100% 100%;
			background-position: 100%;
			font-weight: bold;
			margin: 0 0 $global-margin*0.5 0;
		}
	}
}

#jobs {
	padding: 80px 0 0 0;
	
	.pixelheads {

		display: flex;
		flex-direction: column;
		max-width: $global-width;
		margin: 0 auto;

		&__container {
			display: flex;
			flex-direction: column;
			margin: 40px 0 70px 0;

			@include breakpoint(medium) {
				margin: 100px 0 70px 0;
			}
		}

		&__card_container {
			@include breakpoint(medium) {
				max-width: 48%;

				&:nth-child(even) {
					width: 100%;
					align-self: flex-end;
				}
			}
		}

		&__title {
			font-size: rem-calc(20);
			font-weight: bold;
			line-height: 134%;
			margin: 0 0 20px 0;
		}
	}
}

#call_us {
	
	padding: 80px 0 0 0;
	
	@include breakpoint(medium) {
		position: relative;
		padding: 180px 0 0 0;
		
		&:before {
			content: "";
			position: absolute;
			bottom: 0;
			z-index: -1;
			background: #222222;
			width: 100%;
			height: 80px;
		}
	}
}

#show_gptw {
	position: fixed;
	z-index: 2;
	bottom: 20px;
	right: 20px;
}

.gptw_certificate {
	width: 30%;

	@include breakpoint(medium) {
		width: 100%;
	}
}