// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

@mixin rein-text-alignment {
    @each $size in $breakpoint-classes {
        @include breakpoint($size) {
            @each $align in (left, right, center, justify) {
                @if $size != $-zf-zero-breakpoint {
                    .#{$size}_text-#{$align} {
                        text-align: $align;
                    }
                }
                @else {
                    .text_#{$align} {
                        text-align: $align;
                    }
                }
            }
        }
    }
}