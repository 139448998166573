@import "../base";

h1,
.h1 {
	display: block;
	
	&--shops {
		max-width: 525px;
	}
	
	&--pixelheads {
		max-width: 450px;
	}
	
	&--call_us {
		max-width: 350px;
	}
}

h2,
.h2 {
	display: inline-flex;
	position: relative;
}

h2:after,
.h2:after {
	content: "";
	position: absolute;
	bottom: -8px;
	left: 0;
	height: 4px;
	width: 100%;
	background: rgb(72,193,233);
	background: linear-gradient(90deg, rgba(72,193,233,1) 0%, rgba(134,188,36,1) 100%);
}

.text--regular {
	font-weight: normal;
}

.word_break {
	display: none;
	
	@include breakpoint(small) {
		display: inline-block;
	}
}

.paragraph {
	@include breakpoint(medium) {
		max-width: 75%;
	}
	
	&--subheading {
		font-size: rem-calc(20);
		line-height: 140%;
		padding: 40px 0
	}
	
	&--bold {
		font-weight: bold;
	}
}