.slider {

    &__pagination_bullet {
        width: 12px;
        height: 12px;
        display: inline-block;
        border-radius: $global-radius;
        background: $light-gray;
        opacity: 0.5;
        margin: 0 $global-margin*0.25;
        cursor: pointer;

        &--active {
            opacity: 1;
            background: linear-gradient(45deg, rgba(72, 193, 233, 1) 0%, rgba(134, 188, 36, 1) 100%);
        }
    }
}