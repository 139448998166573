// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group close-button
////

/// Default position of the close button. The first value should be `right` or `left`, and the second value should be `top` or `bottom`.
/// @type List
$closebutton-position: right top !default;

/// Default z-index for a close button.
/// @type Number
$closebutton-z-index: 10 !default;

/// Button size to use as default
/// @type String
/// @see $closebutton-size
/// @see $closebutton-offset-horizontal
/// @see $closebutton-offset-vertical
$closebutton-default-size: medium !default;

/// Right (or left) offset(s) for a close button.
/// @type Number|Map
$closebutton-offset-horizontal: (
        small: 0.66rem,
        medium: 1rem,
) !default;

/// Top (or bottom) offset(s) for a close button.
/// @type Number|Map
$closebutton-offset-vertical: (
        small: 0.33em,
        medium: 0.5rem,
) !default;

/// Size(s) of the close button. Used to generate sizing modifiers.
/// @type Number|Map
$closebutton-size: (
        small: 1.5em,
        medium: 2em,
) !default;

/// The line-height of the close button. It affects the spacing of the element.
/// @type Number
$closebutton-lineheight: 1 !default;

/// Default color of the close button.
/// @type Color
$closebutton-color: $dark-gray !default;

/// Default color of the close button when being hovered on.
/// @type Color
$closebutton-color-hover: $black !default;


/// Get the size and position for a close button. If the input value is a number, the number is returned. If the input value is a config map and the map has the key `$size`, the value is returned.
///
/// @param {Number|Map} $value - A number or map that represents the size or position value(s) of the close button.
/// @param {Keyword} $size - The size of the close button to use.
///
/// @return {Number} The given number or the value found in the map.
@function -zf-get-size-val($value, $size) {
    // Check if the value is a number
    @if type-of($value) == 'number' {
        // If it is, just return the number
        @return $value;
    }
        
        // Check if the size name exists in the value map
    @else if map-has-key($value, $size) {
        // If it does, return the value
        @return map-get($value, $size);
    }
}

/// Sets the size and position of a close button.
/// @param {Keyword} $size [medium] - The size to use. Set to `small` to create a small close button. The 'medium' values defined in `$closebutton-*` variables will be used as the default size and position of the close button.
@mixin close-button-size($size) {
    $x: nth($closebutton-position, 1);
    $y: nth($closebutton-position, 2);
    
    #{$x}: -zf-get-size-val($closebutton-offset-horizontal, $size);
    #{$y}: -zf-get-size-val($closebutton-offset-vertical, $size);
    font-size: -zf-get-size-val($closebutton-size, $size);
    line-height: -zf-get-size-val($closebutton-lineheight, $size);
}

/// Adds styles for a close button, using the styles in the settings variables.
@mixin close-button {
    $x: nth($closebutton-position, 1);
    $y: nth($closebutton-position, 2);
    
    @include disable-mouse-outline;
    position: absolute;
    z-index: $closebutton-z-index;
    color: $closebutton-color;
    cursor: pointer;
    
    &:hover,
    &:focus {
        color: $closebutton-color-hover;
    }
}

@mixin rein-close-button {
    .close_button {
        // Use by default the placeholder of the default size
        @extend %zf-close-button--#{$closebutton-default-size};
        
        @include close-button;
        
        // Generate a placeholder and a class for each size
        @each $name, $size in $closebutton-size {
            @at-root {
                %zf-close-button--#{$name} {
                    @include close-button-size($name);
                }
            }

            &--#{$name} {
                @extend %zf-close-button--#{$name};
            }
        }
        
        &--delete {
            @extend .close_button;
            right: -10px;
            top: -10px;
            border: $button-border;
            border-radius: 100%;
            border-color: get-color(primary);
            color: get-color(primary);
            background: $white;
            padding: 8px 9px;
            cursor: pointer;
            font-size: inherit;
            
            &:focus,
            &:hover {
                border-color: get-color(alert);
                color: get-color(alert);
            }
            
            &:disabled,
            &__disabled {
    
                border-color: get-color(secondary);
                color: get-color(secondary);
                
                &:focus,
                &:hover {
                    border-color: get-color(secondary);
                    color: get-color(secondary);
                }
            }
        }
    }
}