// Foundation for Sites
// https://get.foundation
// Licensed under MIT Open Source

////
/// @group callout
////

/// Default background color.
/// @type Color
$callout-background: $white !default;

/// Default fade value for callout backgrounds.
/// @type Number
$callout-background-fade: 85% !default;

/// Default border style for callouts.
/// @type List
$callout-border: 1px solid rgba($black, 0.25) !default;

/// Default bottom margin for callouts.
/// @type Number
$callout-margin: 0 0 1rem 0 !default;

/// Sizes for Callout paddings.
/// @type Map
$callout-sizes: (
  small: 0.5rem,
  default: 1rem,
  large: 3rem,
) !default;

/// Default font color for callouts.
/// @type Color
$callout-font-color: $body-font-color !default;

/// Default font color for callouts, if the callout has a dark background.
/// @type Color
$callout-font-color-alt: $body-background !default;

/// Default border radius for callouts.
/// @type Color
$callout-radius: $global-radius !default;

/// Amount to tint links used within colored panels. Set to `false` to disable this feature.
/// @type Number | Boolean
$callout-link-tint: 30% !default;

/// Adds basic styles for a callout, including padding and margin.
@mixin callout-base() {
    position: relative;
    margin: $callout-margin;
    padding: $callout-sizes;

    border: $callout-border;
    border-radius: $callout-radius;
    
    // Respect the padding, fool.
    > :first-child {
        margin-top: 0;
    }
    
    > :last-child {
        margin-bottom: 0;
    }
}

/// Generate quick styles for a callout using a single color as a baseline.
/// @param {Color} $color [$callout-background] - Color to use.
@mixin callout-style($color: $callout-background) {
    $background: scale-color($color, $lightness: $callout-background-fade);
    
    background-color: $background;
    color: color-pick-contrast($background, ($callout-font-color, $callout-font-color-alt));
}

@mixin callout-size($padding) {
    padding-top: $padding;
    padding-right: $padding;
    padding-bottom: $padding;
    padding-left: $padding;
}


/// Adds styles for a callout.
/// @param {Color} $color [$callout-background] - Color to use.
@mixin callout($color: $callout-background) {
    @include callout-base;
    @include callout-style($color);
}

@mixin rein-callout {
    .callout {
        @include callout;

        @each $status, $status-defs in $callout-style {
            &--#{$status} {
                @extend .callout;
                
                @if map_has_key($status-defs, padding) {
                    padding: map-get($status-defs, padding);
                }
                @if map_has_key($status-defs, color) {
                    color: map-get($status-defs, color);
                }
                @if map_has_key($status-defs, background-color) {
                    background-color: map-get($status-defs, background-color);
                }
            }
        }

        .close_button {
            color: inherit;
            top: 0;
            right: 8px;
        }
    }
}