// scss-lint:disable ImportantRule, SpaceAfterComma, SingleLinePerProperty

@mixin -motion-ui-defaults {
  transition-duration: map-get($motion-ui-speeds, default);
  transition-timing-function: map-get($motion-ui-easings, default);
}

// Transitions
// - - - - - - - - - - - - - - -
@mixin motion-ui-transitions {
  // Slide
  .slide_in_left    { @include mui-slide(in,  right, 50%); }
  .slide_in_right   { @include mui-slide(in,  left, 50%); }

  // Transition Modifiers
  // - - - - - - - - - - - - - - -

  @each $name, $value in $motion-ui-speeds {
    @if $name != default {
      .#{$name} { transition-duration: $value !important; }
    }
  }

  @each $name, $value in $motion-ui-easings {
    @if $name != default {
      .#{$name} { transition-timing-function: $value !important; }
    }
  }

  @each $name, $value in $motion-ui-delays {
    @if $name != default {
      .#{$name}-delay { transition-delay: $value !important; }
    }
  }
}
